import {
  Button,
  H1,
  H2,
  H3,
  H5,
  Icon,
  ProgressIndicator,
  Section,
} from "@dnb/eufemia"
import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graph_increase, graph_decrease } from "@dnb/eufemia/icons"
import { CircularProgressBar } from "../components/circularProgressBar"

const API = process.env.API_ENDPOINT

const getAsset = (code: string) => {
  return fetch(`${API}/viewasset/${code}`).then(data => data.json())
}

type AssetPrice = {
  value: number
  currency: string
}

type AssetStats = {
  mostRecentPrice: AssetPrice
  dayHigh: AssetPrice
  dayLow: AssetPrice
  marketCap: AssetPrice
  allTimeHigh: AssetPrice
  allTimeHighDate: string
  marketSupply: number
}

type AssetView = {
  date: string
  assetName: string
  assetCode: string
  assetPosition: number
  percentageOfPortfolio: number
  assetStats: AssetStats
}

const Asset = () => {
  const [assetData, setAssetData] = useState<AssetView | undefined>(undefined)

  useEffect(() => {
    getAsset("ETH").then(assetDate => {
      console.log("assetData", assetData)
      setAssetData(assetDate)
    })
  }, [])

  const AllData = {
    value: 436279.64,
    increase: 101.95,
    percentageIncrease: 128,
  }

  const Allocation = () => (
    <Column>
      <div>Your allocation</div>
      <H1>{"$" + AllData.value}</H1>
      <SeaGreen>
        {AllData.increase} ETH (
        {(AllData.percentageIncrease > 0 ? "+" : "-") +
          AllData.percentageIncrease}
        %)
        <Icon
          left="1rem"
          icon={
            AllData.percentageIncrease > 0 ? graph_increase : graph_decrease
          }
        />
      </SeaGreen>
      <div>
        <Button>Sell {assetData.assetCode}</Button>
      </div>
    </Column>
  )

  const CurrentPrice = () => (
    <Column>
      <div>Current price for 1 ETH</div>
      <H1>{"$" + assetData.assetStats.mostRecentPrice.value}</H1>
      <SeaGreen>
        (
        {(AllData.percentageIncrease > 0 ? "+" : "-") +
          AllData.percentageIncrease}
        %)
        <Icon
          left="1rem"
          icon={
            AllData.percentageIncrease > 0 ? graph_increase : graph_decrease
          }
        />
      </SeaGreen>
      <div>
        <Button>Buy {assetData.assetCode}</Button>
      </div>
    </Column>
  )

  const PercentageOfPortofolio = () => (
    <Column>
      <CircularProgressBar
        sqSize={200}
        percentage={assetData.percentageOfPortfolio}
        strokeWidth={7}
      />
    </Column>
  )

  const PriceAndPerformance = () => (
    <div>
      <div>
        <H1 top="1.5rem" bottom="1.5rem">
          Price and Performance
        </H1>
      </div>
      <Row>
        <PriceTable />
        <PerformanceTable />
      </Row>
    </div>
  )

  const PriceTable = () => (
    <PriceContainer>
      <H3 left="1.5rem">
        {assetData.assetName} Price (
        {assetData.assetStats.mostRecentPrice.currency})
      </H3>
      <PriceItem>
        <H5>Current price ({assetData.assetStats.mostRecentPrice.currency})</H5>
        <div>{assetData.assetStats.mostRecentPrice.value}</div>
      </PriceItem>
      <PriceItem>
        <H5>24H High</H5>
        <div>{assetData.assetStats.dayHigh.value}</div>
      </PriceItem>
      <PriceItem>
        <H5>24H Low</H5>
        <div>{assetData.assetStats.dayLow.value}</div>
      </PriceItem>
      <PriceItem>
        <H5>24H Open</H5>
        <div>{assetData.assetStats.dayLow.value}</div>
      </PriceItem>
      <PriceItem>
        <H5>Market Cap</H5>
        <div>{assetData.assetStats.marketCap.value}</div>
      </PriceItem>
      <PriceItem>
        <H5>Circulating Supply</H5>
        <div>{assetData.assetStats.marketSupply}</div>
      </PriceItem>
      <PriceItem>
        <H5>All Time High (ATH)</H5>
        <div>{assetData.assetStats.allTimeHigh.value}</div>
      </PriceItem>
      <PriceItem>
        <H5>ATH Date (DD:MM:YYYY)</H5>
        <div>{assetData.assetStats.allTimeHighDate}</div>
      </PriceItem>
    </PriceContainer>
  )

  const PerformanceTable = () => (
    <PerformanceContainer>
      <H3 left="1.5rem">Periodic Performance</H3>
      <Table>
        <THead>
          <Th>Period</Th>
          <Th>Change</Th>
          <Th>%</Th>
          <Th>High</Th>
        </THead>
        <tbody>
          <tr>
            <TdFirst>1HR</TdFirst>
            <TdSuccess>$22.51</TdSuccess>
            <TdSuccess>0.53% </TdSuccess>
            <Td> $4316.82</Td>
          </tr>
          <tr>
            <TdFirst>24H</TdFirst>
            <TdSuccess>$22.51 </TdSuccess>
            <TdSuccess> 0.53%</TdSuccess>
            <Td>$4316.82 </Td>
          </tr>
          <tr>
            <TdFirst>1W </TdFirst>
            <TdSuccess>$22.51 </TdSuccess>
            <TdSuccess>0.53% </TdSuccess>
            <Td>$4316.82 </Td>
          </tr>
          <tr>
            <TdFirst>1MO </TdFirst>
            <TdSuccess>$22.51 </TdSuccess>
            <TdSuccess>0.53% </TdSuccess>
            <Td>$4316.82 </Td>
          </tr>
          <tr>
            <TdFirst>3MO </TdFirst>
            <TdSuccess>$22.51 </TdSuccess>
            <TdSuccess> 0.53%</TdSuccess>
            <Td>$4316.82 </Td>
          </tr>
          <tr>
            <TdFirst> 1YR</TdFirst>
            <TdSuccess>$22.51 </TdSuccess>
            <TdSuccess>0.53% </TdSuccess>
            <Td>$4316.82 </Td>
          </tr>
          <tr>
            <TdFirst>All time </TdFirst>
            <TdSuccess>$22.51 </TdSuccess>
            <TdSuccess>0.53% </TdSuccess>
            <Td>$4316.82 </Td>
          </tr>
        </tbody>
      </Table>
    </PerformanceContainer>
  )

  return (
    <Layout>
      {assetData ? (
        <>
          <SectionStyled>
            <div>
              <div>
                <H2>
                  {assetData.assetName} ({assetData.assetCode})
                </H2>
                {JSON.stringify(assetData)}
              </div>
              <Row>
                <CurrentPrice />
                <BorderRight />
                <Allocation />
                <BorderRight />
                <PercentageOfPortofolio />
              </Row>
            </div>
          </SectionStyled>
          <PriceAndPerformance />
          <Padding />
        </>
      ) : (
        <div>Loading...</div>
      )}
    </Layout>
  )
}

const Table = styled.table`
  width: 90%;
  text-align: left;
  border-collapse: collapse;
  caption-side: top;
  margin: 2rem 2rem;
`

const THead = styled.thead`
  background-color: var(--color-mint-green-25);
`

const Td = styled.td`
  border-bottom: 1px solid #999;
  width: 25%;
  padding: 1rem 0;
`
const Th = styled.th`
  border-bottom: 1px solid #999;
  width: 25%;
  padding: 1rem 0;
  margin: 0.5rem 0;
`

const TdFirst = styled.td`
  border-bottom: 1px solid #999;
  width: 25%;
  font-weight: 600;
  padding: 1rem 0;
`

const TdSuccess = styled.td`
  border-bottom: 1px solid #999;
  width: 25%;
  color: var(--color-success-green);
  padding: 1rem 0;
`

const Padding = styled.div`
  padding-bottom: 50rem;
`

const PriceContainer = styled.div`
  border: 1px solid var(--color-black-8);
  border-radius: 0.5rem;
  width: 50%;
  margin: 1rem 0;
`

const PerformanceContainer = styled.div`
  border: 1px solid var(--color-black-8);
  border-radius: 0.5rem;
  width: 50%;
  margin: 1rem 0;
  margin-left: 1rem;
`

const PriceItem = styled.div`
  border-top: 1px solid var(--color-black-8);
  display: flex;
  justify-content: space-between;
  margin: 1.5rem;
  padding-top: 1rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const SectionStyled = styled(Section)`
  &.dnb-section:after {
    color: var(--color-mint-green-25);
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
`

const SeaGreen = styled.div`
  text-color: var(--color-sea-green-25);
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
`

const BorderRight = styled.div`
  border-right: 2px solid var(--color-mint-green-50);
  padding-right: 3rem;
  margin: 1rem 0;
`

export default Asset
